import { useCallback } from "react";
import currency from "currency.js";
import { accountTypeMapping } from "../../Dashboard";
import { Label, StatsWrapper, StatWrapper, Value } from "./style";
import { IAccount, AccountTypeEnum } from "../../../interfaces/aevo";

interface IStatsProps {
  accounts: IAccount[];
  totalAccounts: number;
}

export function Stats({ accounts, totalAccounts }: IStatsProps) {
  const filteredAccounts = useCallback(
    (type: AccountTypeEnum) => accounts.filter((acc) => accountTypeMapping[acc.account_type] === type),
    [accounts]
  );

  return (
    <StatsWrapper>
      <StatWrapper>
        <Label>Total Addresses</Label>
        <Value>{currency(totalAccounts, { precision: 0 }).format({ symbol: "" })}</Value>
      </StatWrapper>
      <StatWrapper>
        <Label>Standard Addresses</Label>
        <Value>
          {currency(filteredAccounts(AccountTypeEnum.STANDARD).length, { precision: 0 }).format({ symbol: "" })}
        </Value>
      </StatWrapper>
      <StatWrapper>
        <Label>Market Maker Addresses</Label>
        <Value>
          {currency(filteredAccounts(AccountTypeEnum.MARKET_MAKER).length, { precision: 0 }).format({ symbol: "" })}
        </Value>
      </StatWrapper>
      <StatWrapper>
        <Label>Insurance Fund Addresses</Label>
        <Value>
          {currency(filteredAccounts(AccountTypeEnum.INSURANCE_FUND).length, { precision: 0 }).format({ symbol: "" })}
        </Value>
      </StatWrapper>
    </StatsWrapper>
  );
}
