import { AnimatePresence } from "framer-motion";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Collapse } from "antd";
import { DataContext } from "../../context/DataContext";
import { TradeTypeEnum, useAccount } from "../../hooks/api/admin/useAccount";
import { Positions } from "../Positions";
import { AccountSettings } from "../Settings/AccountSettings";
import { Spinner } from "../shared/Spinner";
import { AccountStats } from "./stats/AccountStats";
import { MMPStats } from "./stats/MMPStats";
import { MarginStats } from "./stats/MarginStats";
import { AccountContentWrapper, AccountStatWrapper, LeftSide, RightSide, TabWrapper } from "./style";
import { TradeHistoryTable } from "./tables/TradeHistoryTable";
import { Orders } from "../Orders";
import { useAccountTransactions } from "../../hooks/api/admin/useAccountTransactions";
import { TxHistoryTable } from "./tables/TxHistoryTable";
import { ISelectOption, Select } from "../shared/Select";
import { COMPONENTS } from "../../constants/design/spacing";

enum TabsEnum {
  POSITIONS = "positions",
  TRADES = "trades",
  TRANSACTIONS = "transactions",
  ORDERS = "orders",
  SETTINGS = "settings",
}

export function Account() {
  const [tradeTypes, setTradeTypes] = useState<Set<TradeTypeEnum>>(
    new Set<TradeTypeEnum>([TradeTypeEnum.TRADE, TradeTypeEnum.LIQUIDATION])
  );

  const { data: accountTxsData, isLoading: accountTxsLoading } = useAccountTransactions();
  const { data: accountData, isLoading: isAccountLoading } = useAccount(undefined, Array.from(tradeTypes));
  const { setAddress, records } = useContext(DataContext);

  const { account, tab } = useParams<{ account: string; tab: string }>();
  const navigate = useNavigate();
  const [activeTab, setTab] = useState<string>(TabsEnum.POSITIONS);

  const onSetTab = useCallback(
    (t: string) => {
      setTab(t);
      navigate(`/account/${account}/${t}`);
    },
    [account, navigate]
  );

  useEffect(() => {
    if (account) setAddress(account);
    if (tab && Object.values(TabsEnum).includes(tab as TabsEnum)) {
      onSetTab(tab);
    } else {
      onSetTab(TabsEnum.POSITIONS);
    }
  }, [account, activeTab, onSetTab, setAddress, tab]);

  const tabs: ISelectOption[] = useMemo(
    () =>
      Object.values(TabsEnum).map((t) => ({
        label: t,
        isActive: t === activeTab,
        onClick: () => onSetTab(t),
      })),
    [activeTab, onSetTab]
  );

  return (
    <div>
      <AnimatePresence>
        <AccountContentWrapper>
          <TabWrapper>
            <Select options={tabs} />
          </TabWrapper>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 420px",
              height: `calc(100vh - 52px - ${COMPONENTS.header.main}px)`,
            }}
          >
            <LeftSide>
              {activeTab === TabsEnum.POSITIONS ? <Positions /> : null}
              {activeTab === TabsEnum.TRANSACTIONS ? (
                <TxHistoryTable
                  data={accountTxsData?.tx_history.transaction_history || []}
                  records={records}
                  isLoading={accountTxsLoading}
                />
              ) : null}
              {activeTab === TabsEnum.TRADES ? (
                <TradeHistoryTable tradeTypes={tradeTypes} setTradeTypes={setTradeTypes} />
              ) : null}
              {activeTab === TabsEnum.ORDERS ? <Orders /> : null}
              {activeTab === TabsEnum.SETTINGS ? <AccountSettings /> : null}
            </LeftSide>
            <RightSide>
              {
                // eslint-disable-next-line no-nested-ternary
                !accountData || isAccountLoading ? (
                  <Spinner />
                ) : (
                  <AccountStatWrapper>
                    <AccountStats data={accountData} />
                    <MMPStats data={accountData} />
                    <MarginStats accountData={accountData} />
                  </AccountStatWrapper>
                )
              }
            </RightSide>
          </div>
        </AccountContentWrapper>
      </AnimatePresence>
    </div>
  );
}
