import styled from "styled-components";
import { Link } from "react-router-dom";
import { BACKGROUND_COLORS, BORDER_COLORS, COLORS, TEXT_COLORS } from "../../constants/design/colors";
import { FONT_SIZE, FONT_STYLE } from "../../constants/design/fontSize";
import { COMPONENTS, SPACING } from "../../constants/design/spacing";
import { AccountTypeEnum } from "../../interfaces/aevo";

export const Table = styled.div`
  background: ${BACKGROUND_COLORS.four};
  margin: ${SPACING.three}px !important;

  > * {
    padding: ${SPACING.three}px;
  }
`;
export const TableRow = styled.div`
  display: flex;
`;
export const TableColumn = styled.div`
  width: 50%;
`;

export const TableHeader = styled(TableRow)`
  border-bottom: 1px solid ${BORDER_COLORS.one};

  ${TableColumn} {
    color: ${TEXT_COLORS.two};
  }
`;

export const GroupedCells = styled.div`
  display: flex;
  align-items: center;

  td {
    width: 60px;
    :first-child {
      width: 90px;
    }
  }
`;

export const ZeroBalanceWrapper = styled.div`
  display: flex;

  > * {
    margin: auto 0;
  }

  // Toggle container
  > div {
    height: 30px;
    width: 45px;
    padding: 0 ${SPACING.two}px;
    margin-right: ${SPACING.two}px;
    cursor: pointer;
  }

  > p {
    color: ${TEXT_COLORS.one};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
`;

export const ActionWrapper = styled.div`
  display: flex;
`;

export const AccountButton = styled.button<{
  isActive?: boolean;
  accountType?: AccountTypeEnum;
}>`
  width: 100%;
  color: ${TEXT_COLORS.one};
  background-color: ${COLORS.blue.two};
  padding: ${SPACING.two}px;
  border-radius: 8px;
  text-align: center;
  font-size: ${FONT_SIZE.three};
  transition: 0.2s;
  margin-top: ${SPACING.two}px;

  img {
    margin-right: ${SPACING.two}px;
  }

  span {
    margin: auto 0;
  }

  border: ${(props) => {
    if (props.isActive) return `1px solid ${COLORS.blue.one}`;

    return `1px solid ${BACKGROUND_COLORS.four}`;
  }};
`;

export const LinkText = styled.a`
  color: ${TEXT_COLORS.one};
  text-decoration: none;
  display: flex;
  flex-direction: column;
  margin-left: ${SPACING.two}px;
  gap: ${SPACING.one}px;

  > * {
    text-align: left;
    margin: 0;
  }
`;

export const FilterCol = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: ${SPACING.two}px;
  }
`;

export const PaginationCol = styled.div`
  display: flex;

  > * {
    margin: auto 0;

    &:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }

  > button {
    padding: 0;
    min-width: unset;
    min-height: unset;
    height: 30px;
    width: 30px;
  }
`;

export const MobilePaginationCol = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${SPACING.two}px;
  color: ${TEXT_COLORS.two};
  ${FONT_STYLE.label.three}

  > * {
    display: flex;
    align-items: center;
  }

  button {
    padding: 0;
    min-width: unset;
    min-height: unset;
    height: 30px;
    width: 30px;

    &:not(:last-of-type) {
      margin-right: ${SPACING.two}px;
    }
  }
`;

export const PaginationButton = styled.button`
  background: ${COLORS.blue.three};
  color: ${COLORS.blue.one};
  border-radius: 6px;
  height: 30px;
  width: 30px;
  border: 1px solid transparent;
  transition: 0.2s all ease-in-out;
  text-align: center;

  &:disabled {
    opacity: 0.4;

    &:hover {
      border: 1px solid transparent;
    }
  }

  &:hover {
    border: 1px solid ${COLORS.blue.one};
  }

  > div {
    width: fit-content;
  }

  &:not(:last-of-type) {
    margin-right: ${SPACING.two}px;
  }
`;

export const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${SPACING.three}px;
`;

export const MobileFooterRow = styled.div`
  overflow: hidden;
  padding: ${SPACING.two}px;
`;

export const QuickAddressTag = styled(Link)<{ isActive?: boolean }>`
  display: inline-flex;
  background: ${BACKGROUND_COLORS.componentSolid};
  padding: ${SPACING.two}px;
  ${FONT_STYLE.data.three};
  border-radius: 6px;
  position: relative;
  transition: 0.1s all ease-in-out;
  border: 1px solid ${BORDER_COLORS.two};
  width: max-content;
  transition: 0.2s ease-in-out all;
  justify-content: space-between;

  &:not(:last-of-type) {
    > *:not(:last-child) {
      margin-right: ${SPACING.two}px;
    }
  }

  &:hover {
    cursor: pointer;
    border: 1px solid ${COLORS.blue.one};
  }

  > img {
    margin: auto 0;
    height: 20px;
    opacity: 0.5;
  }
`;

export const MobileQuickAddressWrapper = styled.div`
  display: flex;
  overflow-x: scroll;
  ${FONT_STYLE.label.three};

  > a {
    padding: ${SPACING.one}px ${SPACING.two}px;
    > span {
      width: max-content;
    }

    &:first-of-type {
      margin-left: ${SPACING.two}px;
    }

    &:last-of-type {
      margin-right: ${SPACING.two}px;
    }

    &:not(:last-of-type) {
      margin-right: ${SPACING.two}px;
    }
  }
`;
