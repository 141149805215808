import styled, { createGlobalStyle, css } from "styled-components";
import { BACKGROUND_COLORS, COLORS, TEXT_COLORS } from "./constants/design/colors";
import { FONT_WEIGHT } from "./constants/design/fontWeight";
import { frostedGlassBg } from "./components/shared/style";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Regular.otf") format("opentype");
  }

  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Regular.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.medium};
  }

  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGrotesk/BananaGrotesk-Regular.otf") format("opentype");
    font-weight: ${FONT_WEIGHT.bold};
    font-style: normal;
  }

  // NUMBERS ONLY
  @font-face {
    font-family: BaseFont;
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Regular-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }
  @font-face {
    font-family: BaseFont;
    font-weight: ${FONT_WEIGHT.medium};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Medium-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }
  @font-face {
    font-family: BaseFont;
    font-weight: ${FONT_WEIGHT.bold};
    src: url("/assets/fonts/BananaGroteskMono/MNKYBananaGroteskMono-Bold-Trial.otf") format("opentype");
    unicode-range: U+30-39;
  }

  body {
    font-family: BaseFont !important;
    background: ${BACKGROUND_COLORS.five};
    color: white;
    margin: 0;
    overflow: hidden;
    
    /* For Firefox */
    * {
      scrollbar-width: none !important;
      -ms-overflow-style: none !important;
    }

    /* For Chrome, Edge, and Safari */
    *::-webkit-scrollbar {
      display: none !important;
    }
  }

  button {
    cursor: pointer;
    border: none;
    background-color: transparent;

    &:disabled {
      cursor: not-allowed;
    }
  }

  a {
    text-decoration: none;
    display: inline-flex;
    color: ${TEXT_COLORS.one};

    > * {
      margin: auto;
    }
  }

  textarea:focus, input:focus{
    outline: none;
  }

  // GLOBAL BOOTSTRAP OVERRIDES
  .container {
    max-width: unset;
  }

  .row {
    padding: 0;
  }

  [class*="col-"] {
    padding: 0;
  }

  .modal-backdrop.show {
    opacity: 0.8;
  }
`;

export const PortalWrapper = styled.div<{ showOverlay?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;

  ${({ showOverlay }) =>
    showOverlay
      ? css`
          ${frostedGlassBg(COLORS.black.two, COLORS.black.two)}
        `
      : css`
          pointer-events: none;
          z-index: -1;
        `}
`;
