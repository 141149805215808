import { useCallback, useContext, useState } from "react";
import { InputWrapper, PrefixWrapper, SearchButton } from "./style";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import { DataContext } from "../../context/DataContext";
import { QUERY_TYPE_TITLES, QueryTypeEnum } from "../Dropdown/DashboardFilterDropdown";
import { IAccount } from "../../interfaces/aevo";
import { useQueryAccounts } from "../../hooks/api/admin/useQueryAccounts";
import { Spinner } from "../shared/Spinner";
import { SPACING } from "../../constants/design/spacing";

interface IAccountFilterProps {
  isMobile?: boolean;
}

export function AccountFilter({ isMobile }: IAccountFilterProps) {
  const { setAccounts, clearFilter, records } = useContext(DataContext);
  const { queryAccount } = useQueryAccounts();
  const [value, setValue] = useState<string>("");
  const [queryType, setQueryType] = useState<QueryTypeEnum>(QueryTypeEnum.ADDRESS);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isClearing, setIsClearing] = useState<boolean>(false);

  const onFilter = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setValue(newValue);
      if (!newValue) {
        clearFilter();
      }
    },
    [clearFilter]
  );

  const onSearch = useCallback(async () => {
    if (value && queryType) {
      setIsSearching(true);
      // eslint-disable-next-line @typescript-eslint/no-shadow
      let accounts: IAccount[] = [];
      if (queryType === QueryTypeEnum.AIRTABLE_TAG) {
        // Find airtable tags with keyword
        Object.entries(records).forEach(([k, v]) => {
          if (v.toLowerCase().includes(value.toLowerCase())) {
            accounts.push({
              account: k,
              username: k,
            } as IAccount);
          }
        });
      } else {
        accounts = await queryAccount(queryType, value);
      }

      setIsSearching(false);
      setAccounts(accounts);
    }
  }, [queryAccount, queryType, records, setAccounts, value]);

  const onClearFilter = useCallback(() => {
    setIsClearing(true);
    clearFilter();
    setValue("");
    setIsClearing(false);
  }, [clearFilter]);

  const onFilterButtonClick = useCallback(() => {
    // Cycle through queryTypeEnum. If hit the end, go back to the beginning
    const queryTypeIndex = Object.values(QueryTypeEnum).indexOf(queryType);
    const nextQueryTypeIndex = queryTypeIndex + 1;
    const nextQueryType =
      nextQueryTypeIndex === Object.values(QueryTypeEnum).length
        ? Object.values(QueryTypeEnum)[0]
        : Object.values(QueryTypeEnum)[nextQueryTypeIndex];
    setQueryType(nextQueryType);
  }, [queryType]);

  return (
    <InputWrapper isMobile={isMobile}>
      <PrefixWrapper>
        <Button type="button" buttonTheme={ButtonThemeEnum.HIGHLIGHT} onClick={onFilterButtonClick}>
          {QUERY_TYPE_TITLES[queryType]}
        </Button>
        <input
          value={value}
          onChange={onFilter}
          placeholder={queryType ? `Filter by ${QUERY_TYPE_TITLES[queryType]}` : "Search"}
        />
      </PrefixWrapper>
      <SearchButton
        style={{ margin: isMobile ? 0 : `0 ${SPACING.two}px` }}
        buttonTheme={ButtonThemeEnum.HIGHLIGHT}
        onClick={onSearch}
        disabled={!value && !queryType}
      >
        {isSearching ? <Spinner /> : "Search"}
      </SearchButton>
      <SearchButton buttonTheme={ButtonThemeEnum.SYSTEM} onClick={() => onClearFilter()} disabled={!value}>
        {isClearing ? <Spinner /> : "Clear"}
      </SearchButton>
    </InputWrapper>
  );
}
