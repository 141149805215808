export enum AdminEndpointEnum {
  VALIDATE = "validate",
  ACCOUNTS = "accounts",

  // Account props are now divided into multiple endpoints
  ACCOUNT = "account",
  ACCOUNT_ORDERS = "account/orders",
  ACCOUNT_TRADES = "account/trades",
  ACCOUNT_TXS = "account/txs",
  ACCOUNT_PORTFOLIO = "account/portfolio",
  ACCOUNT_FLOWS = "account/flows",
  ACCOUNT_STATS = "account/stats",
  ACCOUNT_VOLUME = "account/volume",

  TRADES = "trade-history",
  ORDER_HISTORY = "account/order-history",
  STOP_ORDER_HISTORY = "account/order-history/stops",
  CANCEL_ALL = "force-cancel-all",
  ACCOUNT_SETTINGS = "account-config",
  GLOBAL_SETTINGS = "asset-config",
  SET_USERNAME = "set-username",
  PORTFOLIO_MARGIN = "portfolio-margin",
  CREDIT = "send-credit",
  TRANSFER = "transfer",
  QUERY_ACCOUNTS = "query-accounts",
  MAINTENANCE = "maintenance",
  PRIORITY_TRANSACTIONS = "priority-transactions",
  RESET_TX_QUEUE = "reset-transactions-queue",
  RESET_ONCHAIN_QUEUE = "reset-onchain-queue",
  SKIP_TRANSACTION = "skip-transaction",
  MANUAL_WITHDRAWS = "manual-withdraws",
  APPROVE_WITHDRAW = "approve-withdraw",
  CANCEL_WITHDRAW = "cancel-withdraw",
  RETURN_OWNERSHIP = "return-ownership",
  CHECK_STATE = "check-state",
  SYNC_STATE = "sync-state",
  RISK_POSITIONS = "risk/positions",
  ACCOUNT_REFERRAL_BONUS = "account/referral-bonus",
  ACCOUNT_REFEREE_DISCOUNT = "account/referee-discount",
  SET_GROUP = "set-group",
  DEBUG = "debug",
  ACCOUNT_TPSL_HISTORY = "account/order-history/tpsl",
  ORDERBOOK_STATE = "orderbook-state",
  ORDERBOOK_STATE_BY_SEQ = "orderbook-state-at-seq",
  CHECK_BALANCE = "account/check",

  AIRTABLE_TAGS = "airtable-tags",
  MANUAL_ACCOUNTS = "manual",
  SET_MANUAL_MODE = "manual-mode",

  REFERRAL = "referral"
}

export enum AevoEndpointEnum {
  CAPACITIES = "socket/capacity",
  MARKETS = "markets",
  MARK_PRICE = "mark-history",
}
export enum DeribitEndpointEnum {
  ACCOUNT_SUMMARY = "account_summary",
  ACCOUNT_POSITIONS = "positions",
  ACCOUNT_TRANSACTIONS = "transactions",
}

export enum BinanceEndpointEnum {
  ACCOUNT_POSITIONS = "positions",
  ACCOUNT_TRANSACTIONS = "transactions",
  ACCOUNT = "account",
}

export enum AirtableEndpointEnum {
  ACCOUNT_POSITIONS = "positions",
  IF_TRANSACTIONS = "if-transactions",
  MM_PERPS_TRANSACTIONS = "mm-perps-transactions",
  MM_OPTIONS_TRANSACTIONS = "mm-options-transactions",
  FLAGGED_ACCOUNT_NOTES = "notes",
}

export enum PricerEndpointEnum {
  GET_PRICER_TIMESTAMP = "get-timestamp",
  UPDATE_PRICER = "update-pricer",
}

export enum MMTradesEndpointEnum {
  MM_TRADES = "mm-trade-history",
  MARK_PRICE = "mark-price-history",
  TRADES = "trade-history",
  OPTIONS_INSTRUMENTS = "options-instruments",
  OPTIONS_TRADES = "options-trade-history",
  VERTEX_TRADES = "vertex-trade-history",
  PRELAUNCH_TRADES = "prelaunch-trade-history",
  BYBIT_ASSETS = "bybit-assets",
}

export enum FilledTradesEndpointEnum {
  FILLED_TRADES = "filled-trades",
}

export enum IntercomEndpointEnum {
  CONVERSATIONS = "conversations",
}

export enum PageEndpointEnum {
  DASHBOARD = "/",
  ACCOUNT = "/account",
  BOOK = "/book",
  JSON = "/json/*",
  TX = "/tx",
  WITHDRAW = "/withdraw",
  SETTINGS = "/settings",
  RISK = "/risk",
  MMTRADES = "/mm-trades",
  OPTIONSTRADES = "/options-trades",
  MMSTRATEGIES = "mm-strategies",
  DEBUG = "/debug",
  VOLUME = "/volume",
  INTERCOM = "/intercom",
}
