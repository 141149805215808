import axios from "axios";
import { useCallback, useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { AdminEndpointEnum } from "../../../enums/endpoints";
import { ADMIN_API_URL } from "../../../utils/env";
import { IAccount } from "../../../interfaces/aevo";
import { QueryTypeEnum } from "../../../components/Dropdown/DashboardFilterDropdown";

export const useQueryAccounts = () => {
  const { tokenId } = useContext(AuthContext);

  const endpoint = ADMIN_API_URL(AdminEndpointEnum.QUERY_ACCOUNTS);

  const queryAccount = useCallback(
    async (queryType: QueryTypeEnum, value: string): Promise<IAccount[]> => {
      try {
        if (tokenId) {
          const response = await axios.get(endpoint, {
            params: {
              [queryType]: value,
            },
            headers: {
              Authorization: `Bearer ${tokenId}`,
            },
          });

          return response.data;
        }
        return [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        return [];
      }
    },
    [endpoint, tokenId]
  );

  return {
    queryAccount,
  };
};
