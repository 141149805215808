import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDebug } from "../../hooks/api/admin/useDebug";
import { ICollateral, IDebugState } from "../../interfaces/aevo";
import { Button, ButtonThemeEnum } from "../shared/Buttons/styles";
import {
  AccountContentWrapper,
  AccountStatWrapper,
  LeftSide,
  RightSide,
} from "../Account/style";
import { IKeyValue, KeyValue } from "../Account/stats";
import { KeyValueContainer, StatTitle } from "../Account/stats/style";
import { DebugPositionTable } from "../Account/tables/DebugPositionTable";
import { GlobalFilterWrapper, TextWrapper } from "../Table/style";
import { FilterContainer } from "./style";
import { COMPONENTS } from "../../constants/design/spacing";
import { Spinner } from "../shared/Spinner";

export function Trade() {
  const { debugAccount } = useDebug();
  const [loadedFromURL, setLoadedFromURL] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [tradeId, setTradeId] = useState<string>();
  const [debugResult, setDebugResult] = useState<IDebugState>();

  const location = useLocation();

  const onChangeTradeId = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newTradeId = event.target.value;
      setTradeId(newTradeId);
    },
    []
  );

  const onDebug = useCallback(
    async (tradeIdVal?: string) => {
      if (!tradeIdVal) {
        return;
      }
      setDebugResult(undefined);
      setLoading(true);
      try {
        const response = await debugAccount(tradeIdVal);
        if (response) {
          setDebugResult(response);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [debugAccount]
  );

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tradeIdParam = queryParams.get("tradeId");

    if (tradeIdParam && !loadedFromURL) {
      setTradeId(tradeIdParam);
      onDebug(tradeIdParam); // Trigger the debug function
      setLoadedFromURL(true);
    }
  }, [loadedFromURL, location, onDebug]);

  const mmAfterUpnl = useMemo(() => {
    if (!debugResult) {
      return undefined;
    }
    const debugPositions = debugResult.positions;

    const totalUnrealizedPnl = debugPositions.reduce(
      (sum, position) => sum + Number(position.unrealized_pnl || 0),
      0
    );

    return (
      Number(debugResult.maintenance_margin) + totalUnrealizedPnl
    ).toFixed(6);
  }, [debugResult]);

  const stats: IKeyValue[] = useMemo(
    () => [
      {
        title: "Maintenance Margin",
        value: (
          <TextWrapper>
            <div>{debugResult?.maintenance_margin ?? "-"}</div>
          </TextWrapper>
        ),
      },
      {
        title: "Margin value after uPnL",
        value: (
          <TextWrapper>
            <div>{mmAfterUpnl ?? "-"}</div>
          </TextWrapper>
        ),
      },
      {
        title: "Initial Margin",
        value: (
          <TextWrapper>
            <div>{debugResult?.initial_margin ?? "-"}</div>
          </TextWrapper>
        ),
      },
      {
        title: "Collateral Value",
        value: (
          <TextWrapper>
            <div>{debugResult?.collateral_value ?? "-"}</div>
          </TextWrapper>
        ),
      },
      {
        title: "Equity",
        value: (
          <TextWrapper>
            <div>{debugResult?.equity ?? "-"}</div>
          </TextWrapper>
        ),
      },
    ],
    [debugResult]
  );

  const equityStats: IKeyValue[] = useMemo(() => {
    if (!debugResult) {
      return [];
    }
    return debugResult.collaterals.map((collateral: ICollateral) => ({
      title: `${collateral.collateral_asset} Balance`,
      value: (
        <TextWrapper>
          <div>{collateral.balance ?? "-"}</div>
        </TextWrapper>
      ),
    }));
  }, [debugResult]);

  return (
    <>
      <FilterContainer>
        <GlobalFilterWrapper>
          <input
            value={tradeId}
            onChange={onChangeTradeId}
            placeholder="Trade ID"
          />
        </GlobalFilterWrapper>
        <Button
          buttonTheme={ButtonThemeEnum.NEUTRAL2}
          onClick={() => onDebug(tradeId)}
          style={{
            minHeight: `${COMPONENTS.thinButton}px`,
            maxHeight: `${COMPONENTS.thinButton}px`,
          }}
          disabled={!tradeId}
        >
          {loading ? <Spinner /> : "Debug"}
        </Button>
      </FilterContainer>
      {debugResult && (
        <AccountContentWrapper>
          <div style={{ display: "flex", height: "fit-content" }}>
            <LeftSide>
              <AccountStatWrapper>
                <StatTitle isMobile={false}>Account</StatTitle>
                <KeyValueContainer isMobile={false}>
                  {stats.map((stat, i) => (
                    <KeyValue key={i} title={stat.title} value={stat.value} />
                  ))}
                </KeyValueContainer>
                <StatTitle isMobile={false}>Equity</StatTitle>
                <KeyValueContainer isMobile={false}>
                  {equityStats.map((stat, i) => (
                    <KeyValue key={i} title={stat.title} value={stat.value} />
                  ))}
                </KeyValueContainer>
              </AccountStatWrapper>
            </LeftSide>
            <RightSide>
              <DebugPositionTable positions={debugResult.positions} />
            </RightSide>
          </div>
        </AccountContentWrapper>
      )}
    </>
  );
}
